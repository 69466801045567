.start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

img {
  width: 250px;
}

.start-middle-section {
  align-items: center;
  justify-content: space-around;
  margin-bottom: 4em;
  gap: 5em;
}

.arrow-back {
  padding: 6px 3px 6px 0px;
}
.svg-color:hover path {
  fill: var(--background);
}
.arrows {
  width: 400px;
  height: 129px;
  clip-path: url(#arrows);
  background: linear-gradient(
    90deg,
    rgba(68, 68, 68, 1) 0%,
    rgba(130, 184, 255, 1) 10%,
    rgba(130, 184, 255, 1) 11%,
    rgba(68, 68, 68, 1) 21%
  );
  background-size: 500% 500%;
  animation: movement-arrows 2s linear infinite;
}

@keyframes movement-arrows {
  0% {
    background-position: 120% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
