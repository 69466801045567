.end-middle-section {
  justify-content: center;
  gap: 2em;
}

.end-logo {
  width: 100%;
  margin-top: 2em;
  text-align: center;
}

.end-buttons {
  justify-content: center;
  margin-top: 7vh;
}
