:root {
  --blue: rgb(130, 184, 255);
  --transparent-blue: rgba(123, 246, 255, 0.209);
  --letters: #fff;
  --background: #1f1f1f;
  --foreground: #333333;
  --bubble: #535353;
  --image-background: #717171;
  --grey-text: #bebebe;
  --yellow: #ebda23;
  --red: #ff0000;
  --green: #00ff00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

/* GENERAL FLEX CSS ---------------------------- */
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.flex-row {
  display: flex;
  gap: 1em;
}
/* --------------------------------------------- */

/* GENERAL LOGO CSS ---------------------------- */
.large-logo {
  flex-shrink: 0;
  width: 500px;
  height: 200px;
  margin: 0 auto;
  display: block;
}

.small-logo {
  width: 250px;
  height: 125px;
  margin: 0 auto;
  display: block;
}
/* --------------------------------------------- */

/* GENERAL BUTTONS CSS ------------------------- */
.button {
  border: 8px solid var(--blue);
  border-radius: 18px;
  font-size: 1.9em;
  letter-spacing: 0.085em;
  font-family: 'Roboto', sans-serif;
  color: var(--blue);
  font-weight: 900;
  background-color: var(--background);
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}

.button:hover {
  background-color: var(--blue);
  color: var(--background);
  transition: color 0.1s ease-in-out;
}

.large-button {
  width: 376px;
  height: 72px;
}

.medium-button {
  width: 288px;
  height: 72px;
}

.small-button {
  letter-spacing: 0;
  width: 72px;
  height: 72px;
}

.large-text {
  font-size: 2.4em;
}
/* --------------------------------------------- */

.bold {
  font-weight: 700;
}

.no-gap {
  gap: 0;
}
