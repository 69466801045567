.sidebar {
  position: fixed;
  top: 20px;
  left: 0;
  bottom: 0;
  width: 320px;
  overflow-x: hidden;
  padding-bottom: 5px;
  justify-content: space-between;
}

.sidebar > div {
  gap: 0.3em;
}

.sidebar-text {
  font-size: 2em;
  font-weight: bold;
  color: var(--blue);
  margin-top: 0;
  margin-bottom: 0;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2),
    0px 24px 38px rgba(0, 0, 0, 0.14);
}

.give-up {
  width: fit-content;
  background-color: var(--transparent-blue);
  border-radius: 8px;
  text-align: center;
}

.give-up:hover {
  background-color: rgb(255, 55, 55);
}

.give-up > p {
  padding: 0.5em 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: var(--background);
}
