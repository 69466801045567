.grey-text {
  color: var(--grey-text);
  font-weight: light;
}

.movie-container {
  background-color: var(--bubble);
  border-radius: 8px;
  width: 100%;
  border: none;
  text-align: left;
  font-size: larger;

  margin-top: 8px;
  padding: 0em;
  padding-left: 50px;
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.75);
}

.movie-container:hover {
  background-color: var(--image-background);
}
