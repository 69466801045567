.playground-container {
  background-color: var(--foreground);
  margin: 30px 30px 25px 320px;
  width: 100%;
  padding: 52px 54px;
  border-radius: 18px;
}

.movie-list-title {
  margin-left: 5px;
  margin-top: 1em;
}

.actor-list {
  flex-wrap: wrap;
}

.playground-image {
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.75);
}
