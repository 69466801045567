.portrait {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bubble);
  padding: 9px 9px 0px 9px;
  border-radius: 18px;
}

.large-portrait {
  width: 280px;
  height: 450px;
}

.small-portrait {
  width: 220px;
  height: 370px;
}

.large-shadow {
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2),
    0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12);
}

.small-shadow {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.left {
  transform: rotate(-15deg);
}

.right {
  transform: rotate(15deg);
}

.large-portrait-image {
  width: 280px;
  height: 405px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
}

.small-portrait-image {
  width: 220px;
  height: 325px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
}

.small-text-name {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  height: 35px;
}

.large-text-name {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2),
    0px 24px 38px rgba(0, 0, 0, 0.14);
  height: 35px;
}

.super-small-text-image {
  font-size: 12px;
}

.small-text-image {
  font-size: 16px;
}
.medium-text-image {
  font-size: 19px;
}
.large-text-image {
  font-size: 24px;
}

.empty-image {
  display: flex;
  background-color: var(--image-background);
}
