.top-info-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.top-info-element {
  background-color: var(--foreground);
  border-radius: 20px;
  width: 100%;
  padding: 0;
  border: none;
}

.top-info-text {
  width: 90%;
  margin: 30px auto;
}

.top-info-movies {
  width: 90%;
  margin: 0 auto;
}
