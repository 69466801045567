.top5-button {
  display: flex;
  width: 190px;
  height: 100%;
  border: none;
  background-color: var(--bubble);
  font-size: 1.9em;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  font-weight: 900;
}

.top5-button:hover {
  background-color: var(--image-background);
  transition: color 0.1s ease-in-out;
}

.super-small-portrait-image {
  width: 170px;
  height: 255px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
}
/* background-color: var(--foreground);
  border-radius: 18px;
  width: 100%;
  padding: 0;
  border: none;
} */
