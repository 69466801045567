.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timer {
  width: 238px;

  background-color: var(--bubble);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.blue-text {
  color: var(--blue);
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2),
    0px 24px 38px rgba(0, 0, 0, 0.14);
}

.timer-text {
  margin-bottom: 0.3em;
  margin-top: 0.3em;
}
