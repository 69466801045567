.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
}

.menu-logo {
  padding-top: 20vh;
}

.profile-pic {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  border: 5px solid var(--background);
  background-color: var(--background);
}

.profile-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 17px;
  gap: 15px;
  color: var(--background);
  font-weight: bold;
  letter-spacing: 0.085em;
}

.profile-button {
  border: 8px solid var(--blue);
  border-radius: 18px;
  font-size: 1.9em;
  letter-spacing: 0.085em;
  font-family: 'Roboto', sans-serif;
  color: var(--background);
  font-weight: 900;
  background-color: var(--blue);
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}
