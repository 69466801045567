.how-to-bubble {
  position: relative;
  background-color: var(--foreground);
  border-radius: 18px;
  padding: 3rem 4.7rem 3rem 3rem;
  margin: 0 5vw 15px 5vw;
}

.how-to-bubble > h1 {
  margin-top: 0;
}

.centered {
  text-align: center;
}

.howto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
